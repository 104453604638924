<template>

    <fieldset class="curve-box-condition " v-if="selectedFoot === 'left'">
      <legend class="subHeadingText-condition">Left foot</legend>
      <div class="row">
        <div class="flex-row">
          <div class="source">
            <div class="row static-left-foot d-flex justify-content-around">
    <div class="col-lg-1"></div>
  
    <div v-for="(image, index) in staticImagesArray" :key="index" class="col-lg-2 col-2 pb-2 bg-white border text-center"
  @click="!savedImageIndices.includes(index) && selectImage(image.src, image.label, index)"
  :class="{ 'disabled': savedImageIndices.includes(index), 'selected': selectedImageIndex === index }">
  <p class="text-center label-foot">{{ image.label }}</p>
  <img :src="image.src" />
</div>

  
    <div class="col-lg-1"></div>
  </div>
  
            <div>
  
            </div>
            <div class="row">
              <div class="col-lg-1"></div>
              <div class="col-lg-10 col-12 p-0">
                <fieldset class="curve-box-condition">
                  <legend class="subHeadingText-condition">Condition</legend>
                  <div class="row   ">
                    <div class="col-lg-2"></div>
                    <div class="col-lg-8 col-12">
                      <div class="condition-label ">
                                       
                        <span class="d-flex"> <p style="color: red;font-size: 12px; width: 22px; border-radius: 21px;font-weight: 600;background-color: blue;"></p><p class="ms-2">Ulcer / Callus</p></span> 
                      <span class="d-flex"> <p style="color: red;font-size: 12px; width: 22px; border-radius: 21px;font-weight: 600;background-color: green;"></p><p class="ms-2">Deformity</p></span> 
                      <span class="d-flex"> <p style="color: red;font-size: 12px; width: 22px; border-radius: 21px;font-weight: 600;background-color: red;"></p><p class="ms-2">Pain</p></span> 
                      </div>
                    </div>
                    <div class="col-lg-2"></div>
                  </div>
                </fieldset>
              </div>
              <div class="col-lg-1"></div>
  
  
            </div>
  
  
        
  
            <div class="text-center position-relative canvas-container mt-2 h-100 ">
              <span class="cancel-icon" @click="cancelCanvas">&times;</span>
              <canvas ref="leftCanvas" width="400%" height="400%" class="canvas text-center" @mousedown="startDrawing"
                @mouseup="stopDrawing" @mousemove="draw" @touchstart="startDrawing" @touchend="stopDrawing"
                @touchmove="draw"></canvas>
            </div>
  
            <canvas ref="hiddenCanvas" style="display: none;"></canvas>
  
          
  
           
          </div>
        </div>
      </div>
    </fieldset>
  </template>
  
  <script>
  import leftPlanter from "@/assets/images/L-planter.png";
  import leftMedial from "@/assets/images/L-medial1.png";
  import leftDorsum from "@/assets/images/L-dorsum1.png";
  import leftLateral from "@/assets/images/L-Lateral1.png";
  
  export default {

    props: {
    staticImagesArray: {
      type: Array,
      required: true
    }

},
    
    data() {
      return {
        selectedFoot: "left",
        color: "transparent",
        line: 5,
        isDrawing: false,
        isSaved: false,
        canvasEnabled: false,
        savedImageIndices: [],
        currentEditIndex: null,
        context: null,
        selectedImageIndex: null,
        lastX: 0,
        lastY: 0,
        currentImageSrc: leftLateral,
        currentLabel: "Lateral",
        savedImages: [],
        leftCanvas : [
          { src: leftLateral, label: "Lateral" },
          { src: leftPlanter, label: "Plantar" },
          { src: leftDorsum, label: "Dorsum" },
          { src: leftMedial, label: "Medial" },
        ],
      };
    },

watch: {
  staticImagesArray(newArray) {
    if (newArray && newArray.length > 0) {
      this.currentImageSrc = newArray[0].src;
      this.currentLabel = newArray[0].label;
      this.loadImageToCanvas(this.currentImageSrc);
    }
  }
}
,
    methods: {


      
      cancelCanvas() {
        this.canvasEnabled = false;
        this.selectedImageIndex = null;
      },
      loadImageToCanvas(src) {
        const canvas = this.$refs.leftCanvas;
        this.context = canvas.getContext("2d");
        const img = new Image();
        img.src = src;
        img.onload = () => {
          this.context.clearRect(0, 0, canvas.width, canvas.height);
          this.context.drawImage(img, 0, 0, canvas.width, canvas.height);
        };
      },
      selectImage(src, label, index) {
        this.currentImageSrc = src;
        this.currentLabel = label;
        this.loadImageToCanvas(src);
        this.currentEditIndex = null;
        this.canvasEnabled = true;
        this.selectedImageIndex = index;
      },
      setupCanvasEvents() {
        const canvas = this.$refs.leftCanvas;
        canvas.addEventListener('mousedown', this.startDrawing);
        canvas.addEventListener('mouseup', this.stopDrawing);
        canvas.addEventListener('mousemove', this.draw);
  
        canvas.addEventListener('touchstart', this.startDrawing);
        canvas.addEventListener('touchend', this.stopDrawing);
        canvas.addEventListener('touchmove', this.draw);
      },
      startDrawing(event) {
        event.preventDefault();
        this.isDrawing = true;
        const rect = this.$refs.leftCanvas.getBoundingClientRect();
        const x = event.clientX || event.touches[0].clientX;
        const y = event.clientY || event.touches[0].clientY;
        this.lastX = x - rect.left;
        this.lastY = y - rect.top;
      },
      stopDrawing() {
        this.isDrawing = false;
        this.context.beginPath();
      },
      draw(event) {
        event.preventDefault();
        if (!this.isDrawing) return;
        const rect = this.$refs.leftCanvas.getBoundingClientRect();
        const x = event.clientX || event.touches[0].clientX;
        const y = event.clientY || event.touches[0].clientY;
        const currentX = x - rect.left;
        const currentY = y - rect.top;
  
        this.context.lineWidth = this.line;
        this.context.lineCap = "round";
        this.context.strokeStyle = this.color;
  
        this.context.beginPath();
        this.context.moveTo(this.lastX, this.lastY);
        this.context.lineTo(currentX, currentY);
        this.context.stroke();
  
        this.lastX = currentX;
        this.lastY = currentY;
      },
      saveImage() {
        this.color = ""
        const hiddenCanvas = this.$refs.hiddenCanvas;
        const canvas = this.$refs.leftCanvas;
  
        hiddenCanvas.width = canvas.width;
        hiddenCanvas.height = canvas.height;
  
        const hiddenContext = hiddenCanvas.getContext("2d");
        hiddenContext.drawImage(canvas, 0, 0);
        const savedImageSrc = hiddenCanvas.toDataURL("image/png");
  
        if (this.currentEditIndex !== null) {
          this.savedImages[this.currentEditIndex].src = savedImageSrc;
        } else {
          this.savedImages.push({
            src: savedImageSrc,
            label: this.currentLabel,
          });
          this.savedImageIndices.push(this.staticImages.findIndex(image => image.label === this.currentLabel));
          this.$emit('image-uploaded', this.savedImages);
        }
  
        this.isSaved = true;
        this.canvasEnabled = false;
        this.selectedImageIndex = null;
        setTimeout(() => {
          this.isSaved = false;
        }, 2000);
      },
      editSavedImage(index) {
        const savedImage = this.savedImages[index];
        this.loadImageToCanvas(savedImage.src);
        this.currentLabel = savedImage.label;
        this.currentEditIndex = index;
        this.canvasEnabled = true;
        this.selectedImageIndex = null;
      },
      deleteSavedImage(index) {
        this.savedImages.splice(index, 1);
        this.savedImageIndices.splice(index, 1);
      },
    },

    created: async function () {
      if (this.staticImagesArray && this.staticImagesArray.length > 0) {
    this.currentImageSrc = this.staticImagesArray[0].src;
    this.currentLabel = this.staticImagesArray[0].label;
      this.loadImageToCanvas(this.currentImageSrc);
      this.setupCanvasEvents(); 
}

  }
,
  
  };
  </script>
  
  <style>
  .curve-box {
    margin-bottom: 20px;
  }
  
  .cancel-icon {
    position: absolute;
    top: 10px;
    right: 305px;
    color: #00979e !important;
  
    font-size: 24px;
    cursor: pointer;
  }
  .disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  
  .canvas{
    border: 1px solid black;
  }
  
  .saved-image {
    position: relative;
  }
  
  .saved-image img {
    width: 100%;
    cursor: pointer;
  }
  
  .saved-image .cancel-icon {
    position: absolute;
    top: 0;
    right: 10px;
    color: red;
    font-size: 16px;
    cursor: pointer;
  }
  .label-foot{
    margin-bottom: 0;
  }
  
  .selected {
    border: 2px solid #00979e !important;
    border-radius: 5px !important;
  }
  
  fieldset.curve-box-condition {
    border: 2px solid rgb(201, 197, 197);
    border-radius: 5px;
    padding: 0px 15px 0px 15px;
  }
  
  fieldset.sharp-box {
    border: 2px solid #cbcfcf;
    padding: 15px;
    margin-top: 1.5rem;
  }
  
  legend.subHeadingText-condition {
    padding: 0px 10px;
  
    display: block;
    float: none;
    text-align: center;
    width: auto;
    font-size: 18px;
  }
  .condition-label{
    font-size: 16px;
    display: flex;
      justify-content: space-between;
  
  }
  .canvas:hover {
    cursor: pointer;
  }
  .bg-white{
    background-color: white
  }
  
  @media (max-width: 576px) {
    .source{
    max-width: 100%;
  }
    .canvas-container {
      max-width: 100%;
      overflow: hidden;
    border: 1px solid black;
  
    }
    .label-foot{
      font-size: 12px !important;
    }
    .condition-label{
    font-size: 14px;
    display: flex;
      justify-content: space-between;
  
  }
    .canvas {
      height: auto;
    }
  

    .static-images{
    width: 75px!important;
  }
    .cancel-icon{
      right: 3px !important;
      top: 2px !important;
    }
    .canvas-heading {
      font-size: 1.2rem;
      margin-top: 1rem;
    }
    .subHeadingText, .subHeadingText-condition {
      font-size: 1rem;
    }
    .choose p {
      font-size: 0.9rem;
      margin: 5px 0;
    }
    .saved-image img {
      width: 80px;
      height: auto;
    }
  }
  
  @media (max-width: 768px) {
    .source{
    max-width: 100%;
  }
  
    .canvas {
      height: auto;
      border: none
    }
  }
  
  </style>
  